import React from 'react';

class ArticleParagraph extends React.Component {
    render() {
        return (
            <p className="f5 lh-copy measure mt0-ns center tj">
                {this.props.text}
            </p>
        )
    }
}

export default ArticleParagraph;