import React from 'react';
import mapboxgl from 'mapbox-gl';
import incomeData from './data/income_features.json'
import '../styles/one-percent-club.css'
import SEO from "../components/seo"
import HeaderCentered from '../components/Header/HeaderCentered'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import onePercentClubSocial from '../images/one-percent-club/one-percent-club-social.png'
import ShareButtons from '../components/ShareButtons/ShareButtons'
import SubscribeBox from '../components/SubscribeBox/SubscribeBox';
import ArticleRecommender from '../components/ArticleRecommender/ArticleRecommender'
import ArticleHeader from '../components/Article/ArticleHeader';
import ArticleParagraph from '../components/Article/ArticleParagraph';

var countries = []
incomeData['features'].forEach(elem => {
    countries.push({
        value: elem['properties']['country'],
        label: elem['properties']['country'],
        income: elem['properties']['income']
    })
});

class OnePercentClub extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFeature: undefined,
            selectedCountry: countries[0]
        }
    }

    handleCountrySelectionChange = selectedOption => {
        this.setState({
            selectedCountry: selectedOption
        });

        trackCustomEvent({
            category: 'OnePercentClub',
            action: 'CountrySelectorChanged',
            label: this.state.selectedCountry.value
        });
    }

    componentDidMount() {
        document.title = "Europe's 1% Club"

        mapboxgl.accessToken = 'pk.eyJ1IjoiamltaWNoYWlsaWRpcyIsImEiOiJjazBlNzZxeWUwZWlkM29yejJ5aWRnaXdhIn0.dklyDYSF16YU0FTyLgJydw';
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            // white style
            style: 'mapbox://styles/jimichailidis/ck9ka4z2w0ltr1ipi7auhssx6',
            center: [15, 55],
            zoom: 2.5,
            minZoom: 2.3,
        });

        map.on('load', function () {
            map.addSource('income', {
                'type': 'geojson',
                'data': incomeData
            });

            map.addLayer({
                'id': 'incomeChloropleth',
                'source': 'income',
                'type': 'fill',
                'paint': {
                    'fill-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'income'],
                        0, '#F57C12',
                        20000, '#E2A543',
                        40000, '#FCE3B7',
                        60000, '#D0D9F1',
                        80000, '#779BD7',
                        100000, '#3A6ABC'
                    ],
                    'fill-opacity': 0.75
                }
            }); // add the layer below the country label
        });

        map.on('mousemove', 'incomeChloropleth', (e) => {
            if (e.features.length > 0) {
                this.setState({
                    selectedFeature: e.features[0]
                });

                map.getCanvas().style.cursor = 'pointer';

                trackCustomEvent({
                    category: 'OnePercentClub',
                    action: 'MapSelectedFeature',
                    label: this.state.selectedFeature.properties.country
                });

            }
        });

        map.on('mouseleave', 'incomeChloropleth', (e) => {
            map.getCanvas().style.cursor = "";
        });
    }

    render() {
        return (
            <div className='container incomeDistribution'>
                <SEO title="Europe's One Percent Club" description="How much income makes you as rich as 1% of your country's population?" pathname='/one-percent-club'
                    image={{ src: onePercentClubSocial, width: 1200, height: 600 }} />

                <HeaderCentered />

                <article className="cf ph3 ph5-ns pv4">
                    <ArticleHeader
                        title="Europe's 1% Club"
                        author='Dimitris Michailidis'
                        date='May 27, 2020' />
                    <div className="fn fl-ns w-50-l center">
                        <ArticleParagraph text="Bill Gates, Warren Buffet, Jeff Bezos. Nearly 300 billion combined net worth. A number so stunning it is impossible to even put into perspective. How can one then aspire to join the 'rich club' and become part of the famous 1%?" />
                        <ArticleParagraph text="The truth is that these people do not represent the 1%. In fact, even if we count all the billionaires in the world, they still amount to only 0,00000028% of the population! The one percent club is easier to join than you think." />
                        <ArticleParagraph text="So, what income puts you in the top 1% earners in your country?" />
                        <ArticleParagraph text="Use the following interactive map to find out:" />
                    </div>
                </article>

                <section className='map'>
                    <ul className='legend'>
                        <li style={{ borderTopColor: '#F57C12' }}>0</li>
                        <li style={{ borderTopColor: '#E2A543' }}>20,000</li>
                        <li style={{ borderTopColor: '#FCE3B7' }}>40,000</li>
                        <li style={{ borderTopColor: '#D0D9F1' }}>60,000</li>
                        <li style={{ borderTopColor: '#779BD7' }}>80,000</li>
                        <li style={{ borderTopColor: '#3A6ABC' }}>100,000</li>
                    </ul>
                    <div ref={el => this.mapContainer = el} className='mapContainer'>
                        <p className='infoPanel'>
                            {this.state.selectedFeature ?
                                <span>To be as rich as 1% of the population in <strong>{this.state.selectedFeature.properties.country}</strong>, you have to be making more than <span className='income-underline'>{this.state.selectedFeature.properties.income} &euro;</span> annually.</span>
                                : <span>Hover over (or touch) a country for details.</span>
                            }
                        </p>
                    </div>
                </section>
                <article className="cf ph3 ph5-ns pv4">
                    <div className="fn w-50-l center">
                        <p className='f5 lh-copy measure mt0-ns center tj'>Disposable Income Data from 2018 & 2019 - Source: <a href="https://ec.europa.eu/eurostat/web/products-datasets/product?code=ilc_di01" target="_blank" rel="noopener noreferrer">Eurostat</a></p>
                        <SubscribeBox />
                    </div>
                    <ShareButtons url="https://www.thousandwords.blog/one-percent-club"></ShareButtons>

                    <ArticleRecommender articles={['rebel-writers', 'amsterdam',]} />
                </article>

                {/* <ShareButtons url="https://www.thousandwords.blog/one-percent-club"></ShareButtons> */}

                {/* <ArticleRecommender articles={['rebel-writers', 'amsterdam',]} /> */}
            </div>
        )
    }
}

export default OnePercentClub;