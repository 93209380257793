import React from 'react';
import { Link } from "gatsby"

class HeaderCentered extends React.Component {
    render() {
        return (
            <div className="mw7 center">
                <nav className="db dt-l w-100 border-box pv2 tc">
                    <Link className="v-mid mid-gray link dim" to='/' title="Home">
                        <h1 className="ph3 ph0-l">Thousand Words</h1>
                    </Link>
                </nav>
            </div>
        )
    }
}

export default HeaderCentered;