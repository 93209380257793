import React from 'react';

class ArticleHeader extends React.Component {
    render() {
        return (
            <header className="fn fl-ns w-25-l pr4-ns">
                <h1 className="f2 lh-title fw9 mb3 mt0 pt3 bt bw2">
                    {this.props.title}
                </h1>
                <p className="f6 ttu tracked gray">{this.props.author}</p>
                <time className="f6 ttu tracked gray">{this.props.date}</time>
            </header>
        )
    }
}

export default ArticleHeader;