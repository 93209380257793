import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { Link } from "gatsby"

class ArticleRecommender extends React.Component {
    constructor(props) {
        super(props);

        this.state = { articles: props.articles };
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                query recommendedPosts {
                    allPostsJson {
                      edges {
                        node {
                          id
                          author
                          date
                          title
                          url
                          img {
                            childImageSharp {
                              fluid(maxWidth: 640) {
                                  ...GatsbyImageSharpFluid
                              }
                            }
                          }
                          alt
                          isNew
                          description
                        }
                      }
                    }
                  }  
                `}

                render={data =>
                    <section className="mw7 center">
                        <h3 className="f4 lh-title">Take a look at our other interactive projects:</h3>
                        {
                            data['allPostsJson']['edges'].map(post => this.state.articles.includes(post.node.id) &&
                                <article className="bt bb b--black-10" key={post.node.id}>
                                    <Link className="db pv4 ph3 ph0-l no-underline black dim" to={post.node.url}>
                                        <div className="flex flex-column flex-row-ns">
                                            <div className="pr3-ns mb4 mb0-ns w-100 w-40-ns">
                                                <Img fluid={post.node.img.childImageSharp.fluid} alt={post.node.alt} className="db" />
                                            </div>
                                            <div className="w-100 w-60-ns pl3-ns">
                                                <h1 className="f3 mt0 lh-title">{post.node.title}</h1>
                                                <p className="f6 f5-l lh-copy">
                                                    {post.node.description}
                                                </p>
                                                <p className="f6 lh-copy mv0">By {post.node.author}</p>
                                                <time className="f6 db gray">{post.node.date}</time>
                                            </div>
                                        </div>
                                    </Link>
                                </article>
                            )
                        }
                    </section>
                }
            />
        )
    }
}

export default ArticleRecommender;